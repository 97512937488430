<template>
    <v-dialog
        v-model="dialog"
        max-width="350"
    >

        <v-card>
            <v-form v-model="formValid" ref="form">
                <v-card-title class="primary">
                    <span class="white--text">Detalle de la entrada</span>
                    <v-spacer/>
                    <v-icon color="white" @click="dialog=false">mdi-close-box</v-icon>
                </v-card-title>

                <v-card-text>
                    <div class="text-subtitle-2 my-5">
                        Creado por: {{datos.creado}} ({{datos.dateCreated}})<br/>
                        {{datos.itemNombre}}: {{datos.observaciones}}
                    </div>

                </v-card-text>

                <v-card-actions>
                    <v-text-field
                        label="Motivo para borrar"
                        v-model="motivoBorra"
                        v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                    />
                    <v-spacer/>
                    <v-btn
                        color="error"
                        @click="borra(datos.id)"
                        :loading="loadingBorra"
                        :disabled="!(motivoBorra && motivoBorra.length > 3)"
                        v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                    >
                        Borrar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>           

<script>
export default {
    props:{
        nuevaEntradaDialog:Boolean,
        datos:Object,
    },
    data:()=>({
        formValid:false,
        nuevo:{},
        loadingBorra:false,
        motivoBorra:'',
        
    }),
    methods:{
        cancela(){
            this.$refs.form.reset()
            this.dialog = false
        },

        


        async borra2(id){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraIngreso',
                    data:{id:id, motivo:this.motivoBorra}
                })
                if (borra.data.estado === true){
                    this.dialog=false
                    this.$emit('borrado')
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borra(id){
            
            this.$swal.fire({
                title:'¿Desea borrar?',
                text:'Esta acclón no se puede deshacer',
                icon: 'warning',
                showCancelButton: true
            }).then((result)=>{
                if (result.value){
                    //console.log(id)
                    //console.log(result)
                    this.borra2(id)
                }
            })
            
        },


        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },
    },
    computed:{
        dialog:{
            get(){
                return this.nuevaEntradaDialog
            },
            set(val){
                this.$emit('update:nuevaEntradaDialog',val)
            },
        },
        
        
    },
    watch:{
        dialog(v){
            if (!v){
                this.nuevo = {}
                this.$refs.form.reset()
            }
        },
        
    },
}
</script>

<style>
</style>